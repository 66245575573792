import React, { useState } from 'react';

const LoginForm = ({ onLoginSuccess }) => {
  const [appId, setAppId] = useState('');
  const [appSecret, setAppSecret] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const validateCredentials = async () => {
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch('api/validate-credentials', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ appId, appSecret }),
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Invalid credentials');
      }

      onLoginSuccess({ 
        appId, 
        appSecret,
        channels: data.channels // Pass along initial channel list if provided
      });
    } catch (err) {
      setError(err.message || 'Failed to validate credentials. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateCredentials();
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-center text-3xl font-bold text-gray-900 mb-8">
          Phenix Multiview Dashboard
        </h2>
        
        <form onSubmit={handleSubmit}>
          {error && (
            <div className="mb-4 p-4 text-red-700 bg-red-100 rounded-md">
              {error}
            </div>
          )}
          
          <div className="space-y-4 mb-6">
            <div>
              <label htmlFor="appId" className="block text-sm font-medium text-gray-700 mb-1">
                Application ID
              </label>
              <input
                id="appId"
                type="text"
                value={appId}
                onChange={(e) => setAppId(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your Application ID"
              />
            </div>
            
            <div>
              <label htmlFor="appSecret" className="block text-sm font-medium text-gray-700 mb-1">
                Application Secret
              </label>
              <input
                id="appSecret"
                type="password"
                value={appSecret}
                onChange={(e) => setAppSecret(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your Application Secret"
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? 'Validating...' : 'Login'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;