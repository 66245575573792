import React, { useState, useEffect } from 'react';

const DashboardURL = ({ selectedChannels, applicationId, applicationSecret }) => {
  const [dashboardAlias, setDashboardAlias] = useState('');
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    if (selectedChannels.length === 0) {
      setDashboardAlias('');
      setError('');
      return;
    }

    const generateDashboardAlias = async () => {
      setIsGenerating(true);
      try {
        const authToken = btoa(`${applicationId}:${applicationSecret}`);
        
        const response = await fetch('/api/generate-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${authToken}`
          },
          body: JSON.stringify({
            channels: selectedChannels.map(channel => ({
              channelId: channel.channelId,
              alias: channel.alias
            }))
          })
        });

        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.message || 'Failed to generate dashboard configuration');
        }

        const { alias } = await response.json();
        if (!alias) {
          throw new Error('No alias received from server');
        }
        setDashboardAlias(alias);
        setError('');
      } catch (err) {
        console.error('Dashboard generation error:', err);
        setError(err.message);
        setDashboardAlias('');
      } finally {
        setIsGenerating(false);
      }
    };

    generateDashboardAlias();
  }, [selectedChannels, applicationId, applicationSecret]);

  const getDashboardUrl = () => {
    const baseUrl = window.location.origin;
    return dashboardAlias ? `${baseUrl}/dashboard?alias=${dashboardAlias}` : '';
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(getDashboardUrl());
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  if (selectedChannels.length === 0) {
    return (
      <div className="text-gray-500 p-2">
        Select channels to generate a dashboard URL
      </div>
    );
  }

  return (
    <div className="border-t bg-white p-2 flex-none">
      {error ? (
        <div className="text-red-600 mb-2">
          {error}
        </div>
      ) : isGenerating ? (
        <div className="text-gray-600">
          Generating dashboard configuration...
        </div>
      ) : dashboardAlias ? (
        <>
          <div className="max-w-4xl mx-auto">
            <div className="flex items-center gap-2 p-2 bg-gray-50 rounded-lg border">
              <button
                onClick={handleCopy}
                className="px-3 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 whitespace-nowrap"
              >
                {copied ? 'Copied!' : 'Copy URL'}
              </button>
              <a
                href={getDashboardUrl()}
                target="_blank"
                rel="noopener noreferrer"
                className="truncate text-blue-600 hover:text-blue-800 hover:underline px-2"
              >
                {getDashboardUrl()}
              </a>
            </div>
            <div className="mt-1 text-sm text-gray-500">
              Dashboard URL includes {selectedChannels.length} channel{selectedChannels.length !== 1 ? 's' : ''}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DashboardURL;