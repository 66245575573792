import React, { useState, useEffect, useCallback, Suspense } from 'react';
import phenix from '@phenixrts/sdk';
import { PhenixChannelProvider } from './PhenixChannelContext';
import PhenixVideoPlayer from './PhenixVideoPlayer';

const PreviewArea = ({ selectedChannels, applicationId, applicationSecret, onChannelsReorder }) => {
  const [gridLayout, setGridLayout] = useState({ rows: 1, cols: 1 });
  const [containerRef, setContainerRef] = useState(null);
  const [channelTokens, setChannelTokens] = useState(new Map());
  const [draggedChannel, setDraggedChannel] = useState(null);

  useEffect(() => {
    if (!selectedChannels.length) return;
    const count = selectedChannels.length;
    const sqrt = Math.sqrt(count);
    const cols = Math.ceil(sqrt);
    const rows = Math.ceil(count / cols);
    setGridLayout({ rows, cols });
  }, [selectedChannels]);

  const generateChannelToken = useCallback(async (channelId) => {
    try {
      const authToken = btoa(`${applicationId}:${applicationSecret}`);
      const response = await fetch('/api/generate-channel-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${authToken}`
        },
        body: JSON.stringify({
          channelId,
          expiresInSeconds: 24 * 60 * 60
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate channel token');
      }

      const { token } = await response.json();
      return token;
    } catch (error) {
      console.error(`Token generation error for channel ${channelId}:`, error);
      throw error;
    }
  }, [applicationId, applicationSecret]);

  const getPlayerDimensions = useCallback(() => {
    if (!containerRef) return { width: '100%', height: '100%' };

    const containerWidth = containerRef.offsetWidth;
    const containerHeight = containerRef.offsetHeight;
    const aspectRatio = 16 / 9;

    const width = Math.floor(containerWidth / gridLayout.cols);
    const height = Math.min(
      width / aspectRatio,
      containerHeight / gridLayout.rows
    );

    return {
      width: `${width}px`,
      height: `${height}px`
    };
  }, [containerRef, gridLayout]);

  const getChannelToken = useCallback(async (channelId) => {
    if (!channelTokens.has(channelId)) {
      const token = await generateChannelToken(channelId);
      setChannelTokens(prev => new Map(prev).set(channelId, token));
      return token;
    }
    return channelTokens.get(channelId);
  }, [generateChannelToken, channelTokens]);

  const handleDragStart = (e, channel) => {
    setDraggedChannel(channel);
    e.dataTransfer.effectAllowed = 'move';
    // Add semi-transparent effect to dragged element
    e.currentTarget.style.opacity = '0.5';
  };

  const handleDragEnd = (e) => {
    setDraggedChannel(null);
    e.currentTarget.style.opacity = '1';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (e, targetChannel) => {
    e.preventDefault();
    if (!draggedChannel || targetChannel.channelId === draggedChannel.channelId) return;

    const newChannels = [...selectedChannels];
    const draggedIdx = newChannels.findIndex(ch => ch.channelId === draggedChannel.channelId);
    const targetIdx = newChannels.findIndex(ch => ch.channelId === targetChannel.channelId);

    newChannels.splice(draggedIdx, 1);
    newChannels.splice(targetIdx, 0, draggedChannel);

    onChannelsReorder(newChannels);
  };

  if (!selectedChannels.length) {
    return (
      <div className="w-full h-full flex items-center justify-center bg-gray-100 text-gray-500">
        Select channels to preview
      </div>
    );
  }
  
  return (
    <div 
      ref={setContainerRef}
      className="w-full h-full bg-gray-900 p-2 overflow-hidden"
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${gridLayout.cols}, 1fr)`,
        gridTemplateRows: `repeat(${gridLayout.rows}, 1fr)`,
        gap: '4px',
        alignItems: 'center',
        justifyItems: 'center'
      }}
    >
      {selectedChannels.map((channel) => (
        <div
          key={channel.channelId}
          draggable
          onDragStart={(e) => handleDragStart(e, channel)}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, channel)}
          className="relative bg-black rounded overflow-hidden cursor-move"
          style={getPlayerDimensions()}
        >
          <PhenixChannelProvider key={channel.channelId}>
            <Suspense 
              fallback={
                <div className="w-full h-full flex items-center justify-center bg-gray-800 text-white">
                  Loading...
                </div>
              }
            >
              <ChannelPlayer
                channelId={channel.channelId}
                getToken={getChannelToken}
                phenix={phenix}
              />
            </Suspense>
            <div className="absolute top-0 left-0 p-2 text-white text-sm bg-black bg-opacity-50 w-full cursor-move">
              {channel.alias}
            </div>
          </PhenixChannelProvider>
        </div>
      ))}
    </div>
  );
};

// ChannelPlayer component remains unchanged
const ChannelPlayer = ({ channelId, getToken, phenix }) => {
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getToken(channelId)
      .then(setToken)
      .catch(err => {
        console.error(`Failed to get token for channel ${channelId}:`, err);
        setError(err.message);
      });
  }, [channelId, getToken]);

  if (error) {
    return (
      <div className="w-full h-full flex items-center justify-center bg-red-900 text-white p-4 text-sm">
        Failed to load channel: {error}
      </div>
    );
  }

  if (!token) {
    return (
      <div className="w-full h-full flex items-center justify-center bg-gray-800 text-white">
        Initializing...
      </div>
    );
  }

  return <PhenixVideoPlayer phenix={phenix} token={token} />;
};

export default PreviewArea;