import React, { useState, useMemo } from 'react';

const ChannelList = ({ channels, onChannelSelect }) => {
  const [sortField, setSortField] = useState('alias');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filterText, setFilterText] = useState('');
  const [selectedIds, setSelectedIds] = useState(new Set());

  // Helper to get channel ID - channels use channelId
  const getChannelId = (channel) => channel.channelId;

  // Handle sorting changes
  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  // Handle individual checkbox changes
  const handleCheckboxChange = (channel) => {
    const channelId = getChannelId(channel);
    const newSelectedIds = new Set(selectedIds);
    
    if (newSelectedIds.has(channelId)) {
      newSelectedIds.delete(channelId);
    } else {
      newSelectedIds.add(channelId);
    }
    
    setSelectedIds(newSelectedIds);
    
    // Find selected channels and pass to parent
    const selectedChannels = channels.filter(ch => 
      newSelectedIds.has(getChannelId(ch))
    );
    console.log('Selected channels:', selectedChannels);
    onChannelSelect(selectedChannels);
  };

  // Handle "select all" checkbox
  const handleSelectAll = (e) => {
    const newSelectedIds = new Set();
    
    if (e.target.checked) {
      filteredAndSortedChannels.forEach(channel => {
        newSelectedIds.add(getChannelId(channel));
      });
      setSelectedIds(newSelectedIds);
      onChannelSelect(filteredAndSortedChannels);
    } else {
      setSelectedIds(newSelectedIds);
      onChannelSelect([]);
    }
  };

  // Format date helper function
  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      return new Date(dateString).toLocaleString();
    } catch {
      return '';
    }
  };

  // Sort and filter channels
  const filteredAndSortedChannels = useMemo(() => {
    return channels
      .filter(channel => {
        const searchText = filterText.toLowerCase();
        return (
          channel.alias?.toLowerCase().includes(searchText) ||
          channel.name?.toLowerCase().includes(searchText) ||
          channel.description?.toLowerCase().includes(searchText)
        );
      })
      .sort((a, b) => {
        const aValue = a[sortField] || '';
        const bValue = b[sortField] || '';
        const compareResult = aValue.localeCompare(bValue);
        return sortDirection === 'asc' ? compareResult : -compareResult;
      });
  }, [channels, filterText, sortField, sortDirection]);

  // Debug log for initial render and updates
  console.log('Current state:', {
    channelCount: channels.length,
    selectedCount: selectedIds.size,
    selectedIds: Array.from(selectedIds)
  });

  return (
    <div className="mx-auto">
      {/* Filter input */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Filter channels..."
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Channels table */}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse table-auto">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-4 py-2 text-left">
                <input
                  type="checkbox"
                  checked={
                    filteredAndSortedChannels.length > 0 &&
                    filteredAndSortedChannels.every(channel => 
                      selectedIds.has(getChannelId(channel))
                    )
                  }
                  onChange={handleSelectAll}
                  className="mr-2"
                />
              </th>
              <th 
                className="px-4 py-2 text-left cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('alias')}
              >
                Channel Alias
              </th>
              <th 
                className="px-4 py-2 text-left cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('name')}
              >
                Channel Name
              </th>
              <th 
                className="px-4 py-2 text-left cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('description')}
              >
                Description
              </th>
              <th 
                className="px-4 py-2 text-left cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('created')}
              >
                Created
              </th>
              <th 
                className="px-4 py-2 text-left cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('lastUpdate')}
              >
                Modified
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredAndSortedChannels.map(channel => (
              <tr key={getChannelId(channel)} className="border-t border-gray-200 hover:bg-gray-50">
                <td className="px-4 py-2">
                  <input
                    type="checkbox"
                    checked={selectedIds.has(getChannelId(channel))}
                    onChange={() => handleCheckboxChange(channel)}
                    className="mr-2"
                  />
                </td>
                <td className="px-4 py-2">{channel.alias}</td>
                <td className="px-4 py-2">{channel.name}</td>
                <td className="px-4 py-2">{channel.description}</td>
                <td className="px-4 py-2">{formatDate(channel.created)}</td>
                <td className="px-4 py-2">{formatDate(channel.lastUpdate)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChannelList;