// File: src/components/PhenixChannelContext.jsx
import React, { createContext, useState } from "react";

export const ChannelContext = createContext(null);

// Modified to take channelId as a key
export function PhenixChannelProvider({ children, channelId }) {
    const [channel, setChannel] = useState(null);
    
    return (
        <ChannelContext.Provider value={{ channel, setChannel, channelId }}>
            {children}
        </ChannelContext.Provider>
    );
}