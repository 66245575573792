import React, { useState, useMemo, useEffect, useContext } from 'react';

const SortIndicator = ({ field, currentSort, currentDirection }) => {
  const isActive = currentSort === field;
  const baseClass = "inline-block ml-1";

  return (
    <div className={`${baseClass} flex flex-col -space-y-1`}>
      <svg 
        className={`h-4 w-4 ${
          isActive && currentDirection === 'desc' 
            ? 'text-blue-600' 
            : isActive 
              ? 'text-gray-200' 
              : 'text-gray-400'
        }`}
        fill="currentColor" 
        viewBox="0 0 24 24"
      >
        <path d="M5 14l7-7 7 7z" />
      </svg>
      <svg 
        className={`h-4 w-4 ${
          isActive && currentDirection === 'asc' 
            ? 'text-blue-600' 
            : isActive 
              ? 'text-gray-200' 
              : 'text-gray-400'
        }`}
        fill="currentColor" 
        viewBox="0 0 24 24"
      >
        <path d="M5 10l7 7 7-7z" />
      </svg>
    </div>
  );
};

const PublisherStatusContext = React.createContext();

const PublisherStatus = ({ channelId, applicationId, applicationSecret }) => {
  const [status, setStatus] = useState('unknown');
  const updatePublisherStatus = useContext(PublisherStatusContext);

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const authToken = btoa(`${applicationId}:${applicationSecret}`);
        const response = await fetch('/api/channel-status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${authToken}`
          },
          body: JSON.stringify({ channelId })
        });

        if (!response.ok) throw new Error('Status check failed');
        
        const data = await response.json();
        const newStatus = data.publisherCount > 0 ? 'active' : 'inactive';
        setStatus(newStatus);
        updatePublisherStatus(prev => new Map(prev).set(channelId, newStatus));
      } catch (error) {
        console.error('Status check error:', error);
        const errorStatus = 'error';
        setStatus(errorStatus);
        updatePublisherStatus(prev => new Map(prev).set(channelId, errorStatus));
        setStatus('error');
      }
    };

    checkStatus();
  }, [channelId, applicationId, applicationSecret]);

  return (
    <div className="flex items-center justify-center">
      {status === 'unknown' && (
        <div className="w-3 h-3 rounded-full border-2 border-gray-300 border-t-gray-600 animate-spin" />
      )}
      {status === 'active' && (
        <div className="w-3 h-3 rounded-full bg-green-500" title="Active publishers" />
      )}
      {status === 'inactive' && (
        <div className="w-3 h-3 rounded-full border-2 border-red-500" title="No active publishers" />
      )}
      {status === 'error' && (
        <div className="w-4 h-4 text-yellow-500" title="Status check failed">
          <svg viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 3L2 21h20L12 3zm0 3.6L19.5 19.5h-15L12 6.6z" />
          </svg>
        </div>
      )}
    </div>
  );
};

const ChannelList = ({ channels, onChannelSelect, applicationId, applicationSecret }) => {
  const [sortField, setSortField] = useState('alias');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filterText, setFilterText] = useState('');
  const [selectedIds, setSelectedIds] = useState(new Set());
  const [publisherStatus, setPublisherStatus] = useState(new Map());

  // Helper to get channel ID - channels use channelId
  const getChannelId = (channel) => channel.channelId;

  // Handle sorting changes
  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  // Handle individual checkbox changes
  const handleCheckboxChange = (channel) => {
    const channelId = getChannelId(channel);
    const newSelectedIds = new Set(selectedIds);
    
    if (newSelectedIds.has(channelId)) {
      newSelectedIds.delete(channelId);
    } else {
      newSelectedIds.add(channelId);
    }
    
    setSelectedIds(newSelectedIds);
    
    // Find selected channels and pass to parent
    const selectedChannels = channels.filter(ch => 
      newSelectedIds.has(getChannelId(ch))
    );
    console.log('Selected channels:', selectedChannels);
    onChannelSelect(selectedChannels);
  };

  // Format date helper function
  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      return new Date(dateString).toLocaleString();
    } catch {
      return '';
    }
  };

  // Sort and filter channels
  const filteredAndSortedChannels = useMemo(() => {
    return channels
      .filter(channel => {
        const searchText = filterText.toLowerCase();
        return (
          channel.alias?.toLowerCase().includes(searchText) ||
          channel.name?.toLowerCase().includes(searchText) ||
          channel.description?.toLowerCase().includes(searchText)
        );
      })
      .sort((a, b) => {
        if (sortField === 'status') {
          const statusA = publisherStatus.get(getChannelId(a)) || 'unknown';
          const statusB = publisherStatus.get(getChannelId(b)) || 'unknown';
          const statusOrder = { active: 0, inactive: 1, error: 2, unknown: 3 };
          const compareResult = statusOrder[statusA] - statusOrder[statusB];
          return sortDirection === 'asc' ? compareResult : -compareResult;
        }
        const aValue = a[sortField] || '';
        const bValue = b[sortField] || '';
        const compareResult = aValue.localeCompare(bValue);
        return sortDirection === 'asc' ? compareResult : -compareResult;
      });
  }, [channels, filterText, sortField, sortDirection, publisherStatus]);

  // Debug log for initial render and updates
  // console.log('Current state:', {
  //   channelCount: channels.length,
  //   selectedCount: selectedIds.size,
  //   selectedIds: Array.from(selectedIds)
  // });

  return (
    <PublisherStatusContext.Provider value={setPublisherStatus}>
      <div className="mx-auto">
        {/* Filter input */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Filter channels..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Channels table */}
        <div className="overflow-x-auto">
          <table className="w-full border-collapse table-auto">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-4 py-2 text-left">
                </th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('status')}
                >
                  <div className="flex items-center">
                    Status
                    <SortIndicator field="status" currentSort={sortField} currentDirection={sortDirection} />
                  </div>
                </th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('alias')}
                >
                  <div className="flex items-center">
                    Channel Alias
                    <SortIndicator field="alias" currentSort={sortField} currentDirection={sortDirection} />
                  </div>
                </th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('name')}
                >
                  <div className="flex items-center">
                    Channel Name
                    <SortIndicator field="name" currentSort={sortField} currentDirection={sortDirection} />
                  </div>
                </th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('description')}
                >
                  <div className="flex items-center">
                    Description
                    <SortIndicator field="description" currentSort={sortField} currentDirection={sortDirection} />
                  </div>
                </th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('created')}
                >
                  <div className="flex items-center">
                    Created
                    <SortIndicator field="created" currentSort={sortField} currentDirection={sortDirection} />
                  </div>
                </th>
                <th 
                  className="px-4 py-2 text-left cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('lastUpdate')}
                >
                  <div className="flex items-center">
                    Modified
                    <SortIndicator field="lastUpdate" currentSort={sortField} currentDirection={sortDirection} />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredAndSortedChannels.map(channel => (
                <tr key={getChannelId(channel)} className="border-t border-gray-200 hover:bg-gray-50">
                  <td className="px-4 py-2">
                    <input
                      type="checkbox"
                      checked={selectedIds.has(getChannelId(channel))}
                      onChange={() => handleCheckboxChange(channel)}
                      className="mr-2"
                    />
                  </td>
                  <td className="px-4 py-2">
                    <PublisherStatus 
                      channelId={getChannelId(channel)}
                      applicationId={applicationId}
                      applicationSecret={applicationSecret}
                    />
                  </td>
                  <td className="px-4 py-2">{channel.alias}</td>
                  <td className="px-4 py-2">{channel.name}</td>
                  <td className="px-4 py-2">{channel.description}</td>
                  <td className="px-4 py-2">{formatDate(channel.created)}</td>
                  <td className="px-4 py-2">{formatDate(channel.lastUpdate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </PublisherStatusContext.Provider>
  );
};

export default ChannelList;