import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import Login from '../components/Login';
import ChannelList from '../components/ChannelList';
import PreviewArea from '../components/PreviewArea';
import DashboardURL from '../components/DashboardURL';

const HomePage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [credentials, setCredentials] = useState(null);
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);

  const handleLoginSuccess = ({ appId, appSecret, channels: channelList }) => {
    setIsLoggedIn(true);
    setCredentials({ appId, appSecret });
    setChannels(channelList);
  };

  const handleChannelSelect = (selectedChannelObjects) => {
    setSelectedChannels(selectedChannelObjects);
  };

  const handleChannelsReorder = (reorderedChannels) => {
    setSelectedChannels(reorderedChannels);
  };

  if (!isLoggedIn) {
    return <Login onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="flex-grow min-h-[35%] overflow-auto p-4 border-b">
        <ChannelList 
          channels={channels} 
          onChannelSelect={handleChannelSelect}
        />
      </div>
      <div className="h-[60%] relative">
        <PreviewArea 
          selectedChannels={selectedChannels}
          applicationId={credentials.appId}
          applicationSecret={credentials.appSecret}
          onChannelsReorder={handleChannelsReorder}
        />
      </div>
      <DashboardURL 
        selectedChannels={selectedChannels}
        applicationId={credentials.appId}
        applicationSecret={credentials.appSecret}
      />
    </div>
  );
};

// Initialize React app
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<HomePage />);