// File: src/components/PhenixVideoPlayer.jsx
import React, { useEffect, useContext, useRef } from 'react'; 
import './PhenixVideoPlayer.css';
import { ChannelContext } from './PhenixChannelContext';

function PhenixVideoPlayer({ phenix, token, onResolutionChange }) {
    const { channel, setChannel, channelId } = useContext(ChannelContext);
    const videoRef = useRef(null);

    useEffect(() => {
        // Only create a new channel if we don't already have one
        if (!channel && videoRef.current) {
            console.log(`Creating new channel for ${channelId}`);
            let newChannel = phenix.Channels.createChannel({ 
                videoElement: videoRef.current, 
                token: token 
            });

            newChannel.autoMuted.subscribe(autoMuted => {
                if (autoMuted) {
                    console.log('Playback was auto-muted by browser. Please use user action to initiate `channel.unmute()`');
                }
            });
            
            newChannel.autoPaused.subscribe(autoPaused => {
                if (autoPaused) {
                    console.log('Playback was auto-paused by browser. Please use user action to initiate `channel.play()`');
                }
            });

            // Subscribe to resolution changes
            newChannel.resolution.subscribe(newResolution => {
                console.log(`Resolution changed for ${channelId}:`, newResolution);
                onResolutionChange?.(newResolution);
            });

            setChannel(newChannel);
        }

        // Cleanup function
        return () => { 
            console.log(`Cleanup called for ${channelId}, channel exists: ${!!channel}`);
            // Only cleanup if we have a channel and are actually being unmounted
            if (channel && !videoRef.current) {
                console.log(`Stopping channel for ${channelId}`);
                channel.stop(); 
                setChannel(null);
            }
        };
    }, [token, channelId]);  // Remove videoRef from dependencies

    return (
        <div className="PhenixVideoPlayer">
            <video ref={videoRef} controls muted autoPlay playsInline></video>
        </div>
    );
}

export default PhenixVideoPlayer;