import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import Login from '../components/Login';
import ChannelList from '../components/ChannelList';
import PreviewArea from '../components/PreviewArea';
import DashboardURL from '../components/DashboardURL';

const HomePage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const saved = sessionStorage.getItem('phenixCredentials');
    return !!saved;
  });
  const [credentials, setCredentials] = useState(() => {
    const saved = sessionStorage.getItem('phenixCredentials');
    return saved ? JSON.parse(saved) : null;
  });
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);

  // Fetch fresh channel list on component mount if logged in
  useEffect(() => {
    const refreshChannels = async () => {
      if (credentials) {
        try {
          const response = await fetch('/api/validate-credentials', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              appId: credentials.appId, 
              appSecret: credentials.appSecret 
            }),
          });

          const data = await response.json();
          if (response.ok) {
            setChannels(data.channels || []);
          }
        } catch (error) {
          console.error('Failed to refresh channels:', error);
        }
      }
    };

    refreshChannels();
  }, [credentials]);

  const handleLoginSuccess = ({ appId, appSecret, channels: channelList }) => {
    setIsLoggedIn(true);
    const creds = { appId, appSecret };
    setCredentials(creds);
    sessionStorage.setItem('phenixCredentials', JSON.stringify(creds));
    setChannels(channelList);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setCredentials(null);
    setChannels([]);
    setSelectedChannels([]);
    sessionStorate.removeItem('phenixCredentials');
  };

  const handleChannelSelect = (selectedChannelObjects) => {
    setSelectedChannels(selectedChannelObjects);
  };

  const handleChannelsReorder = (reorderedChannels) => {
    setSelectedChannels(reorderedChannels);
  };

  if (!isLoggedIn) {
    return <Login onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="bg-gray-800 p-4 flex justify-between items-center">
        <h1 className="text-white text-xl font-semibold">Phenix Multiview Dashboard</h1>
        <button
          onClick={handleLogout}
          className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
        >
          Logout
        </button>
      </div>
      <div className="flex-grow min-h-[35%] overflow-auto p-4 border-b">
        <ChannelList 
          channels={channels} 
          onChannelSelect={handleChannelSelect}
          applicationId={credentials.appId}
          applicationSecret={credentials.appSecret}
        />
      </div>
      <div className="h-[60%] relative">
        <PreviewArea 
          selectedChannels={selectedChannels}
          applicationId={credentials.appId}
          applicationSecret={credentials.appSecret}
          onChannelsReorder={handleChannelsReorder}
        />
      </div>
      <DashboardURL 
        selectedChannels={selectedChannels}
        applicationId={credentials.appId}
        applicationSecret={credentials.appSecret}
      />
    </div>
  );
};

// Initialize React app
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<HomePage />);